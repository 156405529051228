<template>
  <v-row 
    align="center" 
    :class="['sector-and-application-picker-row', (($store.getters['projectData/hasErrors']('sector') || $store.getters['projectData/hasErrors']('sectorApplication')) ? 'has-error' : '')]"
    
    >
    <v-item-group
      v-model="step"
      class="shrink mr-6 sector-colum"
    >
      <v-item
        v-for="sector in $store.getters['api/getSectors']"
        :key="'sector_'+sector.id"
        :id="'sector_'+sector.id"
        :class="['mx-auto sector-card', (selectedSector && selectedSector.id == sector.id ? 'selected':'')]"
        v-slot="{ active, toggle }"
      >
          <v-card
            max-width="150"
            @click="toggle(); selectSector(sector); removeErrorKey('sector')"
            :input-value="active"
          >
            <v-img
              :src="sector.image_path"
              height="65px"
            ></v-img>
      
            <v-card-subtitle>
              {{ sector.internal_name }}
            </v-card-subtitle>
          </v-card>
      </v-item>
    </v-item-group>

    <v-col>
      <v-window
        v-if="selectedSector"
        v-model="step"
        class="elevation-1 sector-application-window"
        vertical
      >
      
        <v-window-item>
          <v-row>
            <v-col 
              v-for="application in selectedSector.applications"
              :key="'application_'+application.id"
              :id="'application_'+application.id"
              cols="12"
              md="4"
              sm="6"
            >

            <v-card
              :class="['mx-auto sector-application-card', (selectedSectorApplication && selectedSectorApplication.id == application.id ? 'selected':'')]"
              @click="selectSectorApplication(application); removeErrorKey('sectorApplication')"
            >
              <v-img
                :src="application.image_path"
                height="100px"
              ></v-img>

              <v-card-subtitle>
                {{ application.internal_name }}
              </v-card-subtitle>
            </v-card>
            </v-col>
          </v-row>
          
        </v-window-item>
      </v-window>
      <v-window
        v-if="!selectedSector"
      >
        <span>
          <i class="mdi mdi-chevron-double-left"></i> 
           {{ $t(`projectData.details.sectorAndApplication.instructions`) }} 
          </span>
      </v-window>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SectorAdnApplicationPicker',

  data () {
    return {
      length: 7,
      step: null,
    }
  },
  
  computed: {
    validationErrors: {
      get () {
        return this.$store.getters['projectData/getValidationErrors']
      },
      set (value) {
        this.$store.commit('projectData/setValidationErrors', value)
      }
    },
    selectedSector: {
      get() {
        let currSectorId = this.$store.getters['projectData/getSector']
        return this.$store.getters['api/getSectors'].find((item) => { return item.id == currSectorId })
      },
      set() {}
    },
    selectedSectorApplication: {
      get() {
        let currSectorAppId = this.$store.getters['projectData/getSectorApplication']
        return this.selectedSector.applications.find((item) => { return item.id == currSectorAppId })
      },
      set() {}
    }
  },

  methods: {
    selectSector(sector) {
      this.selectedSector = sector
      this.selectedSectorApplication = null //when selecting sector null application if some other is alredy selected
      this.$store.commit('projectData/setSector', sector.id)
      this.$store.commit('projectData/setSectorApplication', null)
    },
    selectSectorApplication(application) {
      this.selectedSectorApplication = application
      this.$store.commit('projectData/setSectorApplication', application.id)
    },
    nextStep (step) {
      this.step = step
    },
    removeErrorKey(key) {
      this.$store.dispatch('projectData/removeErrorKey', key)
    }
  },
}
</script>
