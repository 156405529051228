<template>
  <div class="project-details-form-container">
    <v-container fluid v-if="validatingProjectDataForm">
      <v-row>
          <v-col class="spinner-col">
            <v-progress-circular
              class="validating-project-data-form-spinner"
              indeterminate
              color="primary"
              v-if="validatingProjectDataForm"
            ></v-progress-circular>
          </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!validatingProjectDataForm">
      <v-row v-if="validationErrors">
        <v-col>
          <v-alert
            dense
            outlined
            type="error"
          >
            {{ $t(`validation.generic`) }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            :label="$t(`projectData.details.name.label`)"
            hide-details="auto"
            v-model="projectName"
            :error="$store.getters['projectData/hasErrors']('projectName')"
            :rules="[rules.required]"
            @input="removeErrorKey('projectName')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select
              v-model="projectCountry"
              :items="$store.getters['api/getCountries']"
              menu-props="auto"
              :label="$t(`projectData.details.country.label`)"
              prepend-icon="mdi-map"
              single-line
              item-value="id"
              item-text="nicename"
              :error="$store.getters['projectData/hasErrors']('projectCountry')"
              :rules="[rules.required]"
              @input="removeErrorKey('projectCountry')"
            ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            :label="'Water types'"
            v-model="waterType"
            row
            :error="$store.getters['projectData/hasErrors']('projectConditions.waterType')"
            :rules="[rules.required]"
            @change="removeErrorKey('projectConditions.waterType')"
          >
            <v-radio
              v-for="waterType in $store.getters['api/getWaterTypes']"
              :key="'waterType_' + waterType"
              name="waterType"
              :label="$t(`projectData.details.waterType.${waterType}`)"
              :value="waterType"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <sector-and-application-picker></sector-and-application-picker> 
        </v-col>
      </v-row>
      <v-row>
        <v-col class="no-padding">
          <v-divider class="divider-margin invisible-divider"></v-divider>
          <project-additional-details-form></project-additional-details-form>
          <v-divider class="divider-margin"></v-divider>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectorAndApplicationPicker from './SectorAndApplicationPicker.vue'
import ProjectAdditionalDetailsForm from './ProjectAdditionalDetailsForm.vue'

export default {
  name: 'ProjectDetailsForm',

  components: {
    SectorAndApplicationPicker,
    ProjectAdditionalDetailsForm
  },

  data () {
    return {
       rules: {
        required: value => !!value || this.$t(`validation.required`),
      }
    }
  },

  methods: {
    removeErrorKey(key) {
      this.$store.dispatch('projectData/removeErrorKey', key)
    }
  },

  computed: {
    projectName: {
      get () {
        return this.$store.getters['projectData/getProjectName']
      },
      set (value) {
        this.$store.commit('projectData/setProjectName', value)
      }
    },
    projectCountry: {
      get () {
        return this.$store.getters['projectData/getProjectCountry']
      },
      set (value) {
        this.$store.commit('projectData/setProjectCountry', value)
      }
    },
    waterType: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsWaterType']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsWaterType', value)
      }
    },
    validatingProjectDataForm: {
      get () {
        return this.$store.getters['projectData/getValidatingProjectDataForm']
      },
      set (value) {
        this.$store.commit('projectData/setValidatingProjectDataForm', value)
      }
    },
    validationErrors: {
      get () {
        return this.$store.getters['projectData/getValidationErrors']
      },
      set (value) {
        this.$store.commit('projectData/setValidationErrors', value)
      }
    },
}

  

}
</script>
