<template>
  <div :class="['finish-project-container', (validatingProject ? 'min-size' : '')]">
    <v-container fluid v-if="validatingProject">
      <v-row>
          <v-col class="spinner-col">
            <v-progress-circular
              class="finish-project-spinner"
              indeterminate
              color="primary"
              v-if="validatingProject"
            ></v-progress-circular>
          </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!validatingProject" class="pl-0 pt-0 pb-4">
      <v-row>
        <v-col>
            {{ $t(`finish.note`) }}
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>

<script>

export default {
  name: 'FinishProject',


  data () {
    return {}
  },

  computed: {
    validatingProject: {
      get () {
        return this.$store.getters['projectData/getValidatingProject']
      },
      set (value) {
        this.$store.commit('projectData/setValidatingProject', value)
      }
    },
    validationErrors: {
      get () {
        return this.$store.getters['projectData/getValidationErrors']
      },
      set (value) {
        this.$store.commit('projectData/setValidationErrors', value)
      }
    },
  },
}
</script>
