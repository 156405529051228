<template>
  <div class="commercial-customer-form">
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerName"
          :label="$t(`customerDetails.commercialCustomer.customerName.label`)"
          required
          :error="$store.getters['projectData/hasErrors']('customer.name')"
          @input="removeErrorKey('customer.name')"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerEmail"
          :label="$t(`customerDetails.commercialCustomer.customerEmail.label`)"
          required
          :error="$store.getters['projectData/hasErrors']('customer.email')"
          @input="removeErrorKey('customer.email')"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="customerCountry"
          :items="$store.getters['api/getCountries']"
          menu-props="auto"
          :label="$t(`customerDetails.commercialCustomer.customerCountry.label`)"
          hide-details
          prepend-icon="mdi-map"
          single-line
          item-value="id"
          item-text="nicename"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerCompany"
          :label="$t(`customerDetails.commercialCustomer.customerCompany.label`)"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerRole"
          :label="$t(`customerDetails.commercialCustomer.customerRole.label`)"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerStreet"
          :label="$t(`customerDetails.commercialCustomer.customerStreet.label`)"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerPostalCode"
          :label="$t(`customerDetails.commercialCustomer.customerPostalCode.label`)"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerPlace"
          :label="$t(`customerDetails.commercialCustomer.customerPlace.label`)"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="customerPhone"
          :label="$t(`customerDetails.commercialCustomer.customerPhone.label`)"
          required
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CommercialCustomerForm',

  data () {
    return {
      rules: {
        required: value => !!value || this.$t(`validation.required`),
      }
    }
  },

  computed: {
    validationErrors: {
      get () {
        return this.$store.getters['projectData/getValidationErrors']
      },
      set (value) {
        this.$store.commit('projectData/setValidationErrors', value)
      }
    },
    customerName: {
      get () {
        return this.$store.getters['projectData/getCustomerName']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerName', value)
      }
    },
    customerStreet: {
      get () {
        return this.$store.getters['projectData/getCustomerStreet']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerStreet', value)
      }
    },
    customerPostalCode: {
      get () {
        return this.$store.getters['projectData/getCustomerPostalCode']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerPostalCode', value)
      }
    },
    customerPlace: {
      get () {
        return this.$store.getters['projectData/getCustomerPlace']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerPlace', value)
      }
    },
    customerCountry: {
      get () {
        return this.$store.getters['projectData/getCustomerCountry']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerCountry', value)
      }
    },
    customerPhone: {
      get () {
        return this.$store.getters['projectData/getCustomerPhone']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerPhone', value)
      }
    },
    customerEmail: {
      get () {
          return this.$store.getters['projectData/getCustomerEmail']
        },
        set (value) {
          this.$store.commit('projectData/setCustomerEmail', value)
        }
    },
    customerCompany: {
      get () {
          return this.$store.getters['projectData/getCustomerCompany']
        },
        set (value) {
          this.$store.commit('projectData/setCustomerCompany', value)
        }
    },
    customerRole: {
      get () {
          return this.$store.getters['projectData/getCustomerRole']
        },
        set (value) {
          this.$store.commit('projectData/setCustomerRole', value)
        }
    },
  },

  methods: {
    removeErrorKey(key) {
      this.$store.dispatch('projectData/removeErrorKey', key)
    }
  },

}
</script>