<template>
  <v-expansion-panels focusable>
    <v-expansion-panel>
      <v-expansion-panel-header :color="'#D2D6ED'">
        {{ $t(`projectData.details.addAdditionalInfo`) }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col
            cols="12"
          >
            <v-textarea
              :label="$t(`projectData.details.projectDescription.label`)"
              :hint="$t(`projectData.details.projectDescription.hint`)"
              v-model="projectDescription"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="waveHeight"
              :label="$t(`projectData.details.waveHeight.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
                <v-icon
                  slot="append"
                >
                  m
                </v-icon>
            </v-text-field>
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="waterDepthMin"
              :label="$t(`projectData.details.waterDepthMin.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
                <v-icon
                  slot="append"
                >
                  m
                </v-icon>
            </v-text-field>
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="waterDepthMax"
              :label="$t(`projectData.details.waterDepthMax.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
                <v-icon
                  slot="append"
                >
                  m
                </v-icon>
            </v-text-field>
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="tidalRange"
              :label="$t(`projectData.details.tidalRange.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
                <v-icon
                  slot="append"
                >
                  m
                </v-icon>
            </v-text-field>
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="waterFlowRate"
              :label="$t(`projectData.details.waterFlowRate.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
                <v-icon
                  slot="append"
                >
                  m
                </v-icon>
            </v-text-field>
          </v-col>
          <v-col
            cols="4"
          >
            <v-text-field
              v-model="windSpeed"
              :label="$t(`projectData.details.windSpeed.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
                <v-icon
                  slot="append"
                >
                  m/s
                </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              :label="$t(`projectData.details.underwaterAnchoringRequired.label`)"
              v-model="underwaterAnchoringRequired"
              row
            >
              <v-radio
                name="underwaterAnchoringRequired"
                :label="$t(`projectData.details.underwaterAnchoringRequired.values.yes`)"
                :value="1"
              ></v-radio>
              <v-radio
                name="underwaterAnchoringRequired"
                :label="$t(`projectData.details.underwaterAnchoringRequired.values.no`)"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              :label="$t(`projectData.details.underwaterSubstrate.label`)"
              v-model="underwaterSubstrate"
              row
            >
              <v-radio
                v-for="underwaterSubstrate in this.$store.getters['api/getUnderwaterTypes']"
                :key="'underwaterSubstrate_' + underwaterSubstrate"
                name="underwaterSubstrate"
                :label="$t(`projectData.details.underwaterSubstrate.values.${underwaterSubstrate}`)"
                :value="underwaterSubstrate"
              ></v-radio>
              <v-radio
                name="waterType"
                :label="$t(`projectData.details.underwaterSubstrate.values.custom.value`)"
                value=" "
              ></v-radio>

              <v-text-field
                class="project-custom-input-field"
                v-if="canCustomFieldBeDisplayed('underwaterSubstrate')"
                v-model.trim="underwaterSubstrate"
                :label="$t(`projectData.details.underwaterSubstrate.values.custom.label`)"
                required
              ></v-text-field>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              :label="$t(`projectData.details.shoreAnchoringRequired.label`)"
              v-model="shoreAnchoringRequired"
              row
            >
              <v-radio
                name="shoreAnchoringRequired"
                :label="$t(`projectData.details.shoreAnchoringRequired.values.yes`)"
                :value="1"
              ></v-radio>
              <v-radio
                name="shoreAnchoringRequired"
                :label="$t(`projectData.details.shoreAnchoringRequired.values.yes`)"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
           <v-radio-group
              :label="'Shore substrate'"
              v-model="shoreSubstrate"
              row
            >
              <v-radio
                v-for="shoreSubstrate in this.$store.getters['api/getShoreTypes']"
                :key="'shoreSubstrate_' + shoreSubstrate"
                name="shoreSubstrate"
                :label="$t(`projectData.details.shoreSubstrate.values.${shoreSubstrate}`)"
                :value="shoreSubstrate"
              ></v-radio>
              <v-radio
                name="waterType"
                :label="$t(`projectData.details.shoreSubstrate.values.custom.value`)"
                value=" "
              ></v-radio>

              <v-text-field
                class="project-custom-input-field"
                v-if="canCustomFieldBeDisplayed('shoreSubstrate')"
                v-model.trim="shoreSubstrate"
                :label="$t(`projectData.details.shoreSubstrate.values.custom.label`)"
                required
              ></v-text-field>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              :label="$t(`projectData.details.loadMax.label`)"
              v-model="loadMax"
              row
            >
              <v-radio
                name="load"
                :label="$t(`projectData.details.loadMax.values.under_350`)"
                :value="350"
              ></v-radio>
              <v-radio
                name="load"
                :label="$t(`projectData.details.loadMax.values.other.value`)"
                value=" "
              ></v-radio>
            </v-radio-group>

            <v-text-field
              v-if="canCustomFieldBeDisplayed('loadMax')"
              class="project-inline-flex-form"
              v-model.number="loadMax"
              :label="$t(`projectData.details.loadMax.values.other.label`)"
              required
              type="number"
              min="0"
              max="20"
              step="0.1"
            >
              <v-icon
                slot="append"
              >
                kg
              </v-icon>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-radio-group
              :label="$t(`projectData.details.gatewayRequired.label`)"
              v-model="gatewayRequired"
              row
            >
              <v-radio
                name="gatewayRequired"
                :label="$t(`projectData.details.gatewayRequired.values.yes`)"
                :value="1"
              ></v-radio>
              <v-radio
                name="gatewayRequired"
                :label="$t(`projectData.details.gatewayRequired.values.no`)"
                :value="0"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="gatewayLength"
              :label="$t(`projectData.details.gatewayLength.label`)"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="gatewayWidth"
              :label="$t(`projectData.details.gatewayWidth.label`)"
              required
            ></v-text-field>
          </v-col>
        </v-row>             
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'ProjectAdditionalDetailsForm',

  data () {
    return {
      rules: {
        projectDescription: [
          value => !!value || this.$t(`validation.required`),
        ],
      },   
    }
  },

  methods: {
    canCustomFieldBeDisplayed (type) {
      switch (type) {
        case 'underwaterSubstrate':
          //if underwaterSubstrate is null OR existing in constants (user used radio button) hide custom input
          if (!this.underwaterSubstrate || this.$store.getters['api/getUnderwaterTypes'].includes(this.underwaterSubstrate)) {
            return false
          }
          break
        case 'shoreSubstrate':
          //if shoreSubstrate is null OR existing in constants (user used radio button) hide custom input
          if (!this.shoreSubstrate || this.$store.getters['api/getShoreTypes'].includes(this.shoreSubstrate)) {
            return false
          }
          break
        case 'loadMax':
            if (!this.loadMax || this.loadMax == 350) {
              return false
            }
            break
      }
      return true
    },
  },
 
  computed: {
    projectDescription: {
      get () {
        return this.$store.getters['projectData/getProjectDescription']
      },
      set (value) {
        this.$store.commit('projectData/setProjectDescription', value)
      }
    },
    waveHeight: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsWaveHeight']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsWaveHeight', value)
      }
    },
    waterDepthMin: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsWaterDepthMin']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsWaterDepthMin', value)
      }
    },
    waterDepthMax: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsWaterDepthMax']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsWaterDepthMax', value)
      }
    },
    tidalRange: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsTidalRange']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsTidalRange', value)
      }
    },
    waterFlowRate: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsWaterFlowRate']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsWaterFlowRate', value)
      }
    },
    windSpeed: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsWindSpeed']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsWindSpeed', value)
      }
    },
    underwaterAnchoringRequired: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsUnderwaterAnchoringRequired']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsUnderwaterAnchoringRequired', value)
      }
    },
    underwaterSubstrate: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsUnderwaterSubstrate']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsUnderwaterSubstrate', value)
      }
    },
    shoreAnchoringRequired: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsShoreAnchoringRequired']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsShoreAnchoringRequired', value)
      }
    },
    shoreSubstrate: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsShoreSubstrate']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsShoreSubstrate', value)
      }
    },
    loadMax: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsLoadMax']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsLoadMax', value)
      }
    },
    gatewayRequired: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsGatewayRequired']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsGatewayRequired', value)
      }
    },
    gatewayLength: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsGatewayLength']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsGatewayLength', value)
      }
    },
    gatewayWidth: {
      get () {
        return this.$store.getters['projectData/getProjectConditionsGatewayWidth']
      },
      set (value) {
        this.$store.commit('projectData/setProjectConditionsGatewayWidth', value)
      }
    },
}

}
</script>
