<template>
  <div class="project-data full-height">
    <v-container class="full-height project-data-container" fluid>
      <v-row class="button-row">
        <v-col cols="12" class="button-container">
          <router-link :to="{ name: 'configurator' }" class="plain-link back">
            <div class="button-back">
                <v-icon size="43" color="black"> mdi-chevron-left</v-icon>
                <span class="button-back-text">
                  {{ $t(`general.navigation.buttons.back`) }}
                </span>
            </div>
          </router-link>    
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12" lg="2" cols="12">
          <div class="content-right-container" style="height: 100%">
            <div class="instrcutions">
              <h3 class="instrcutions-title">
                {{ $t(`projectData.title`) }}
              </h3>
              <h5 class="instructions-context">
                {{ $t(`projectData.subtitle`) }}
              </h5>
            </div>
          </div>
        </v-col>
        <v-col md="12" lg="10" cols="12">
          <div class="content-left-container">
            <v-card>
              <v-stepper v-model="tab" vertical>
                <v-stepper-step :complete="tab > 1" step="1">
                  {{ $t(`projectData.stepper.projectData.title`) }}
                  <small>{{ $t(`projectData.stepper.projectData.subtitle`) }}</small>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <project-details-form></project-details-form>
                  <div class="project-details-buttons">
                    <v-btn 
                      class="project-details-button"
                      color="primary" 
                      @click="validateProjectDetails"
                    >
                      {{ $t(`general.navigation.buttons.continue`) }}
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-step :complete="tab > 2" step="2">
                  {{ $t(`projectData.stepper.personalData.title`) }}
                  <small>{{ $t(`projectData.stepper.personalData.subtitle`) }}</small>
                </v-stepper-step>

                <v-stepper-content step="2">
                  <customer-details-form></customer-details-form>
                  <div class="customer-details-buttons">
                    <v-btn 
                      class="customer-details-button"
                      color="primary" 
                      @click="validateCustomerDetails"
                    >
                      {{ $t(`general.navigation.buttons.continue`) }}
                    </v-btn>                        
                    <v-btn 
                      class="customer-details-button"
                      color="warning"
                      dark 
                      @click="tab = 1"
                    >
                      {{ $t(`general.navigation.buttons.back`) }}
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-step :complete="tab > 3" step="3">
                  {{ $t(`projectData.stepper.finish.title`) }}
                  <small>{{ $t(`projectData.stepper.finish.subtitle`) }}</small>
                </v-stepper-step>

                <v-stepper-content step="3">
                  <finish-project></finish-project>
                  <div class="finish-project-buttons">
                    <v-btn 
                      class="finish-project-button finish"
                      color="primary" 
                      @click="finishProject"
                    >
                      {{ $t(`general.navigation.buttons.finish`) }}
                    </v-btn>                        
                    <v-btn 
                      class="finish-project-button back"
                      color="warning"
                      dark 
                      @click="tab = 2"
                    >
                      {{ $t(`general.navigation.buttons.back`) }}
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import CustomerDetailsForm from '../components/project-data/CustomerDetailsForm.vue'
import ProjectDetailsForm from '../components/project-data/ProjectDetailsForm.vue'
import FinishProject from '../components/project-data/FinishProject.vue'
import router from '../plugins/router';


export default {
  components: {
    CustomerDetailsForm,
    ProjectDetailsForm,
    FinishProject,
  },

  name: 'ProjectData',

  data () {
    return {
      tab: '1'
    }
  },

  methods: {
    validateProjectDetails() {
      this.$store.dispatch("projectData/validateProjectData").then(() => {
        if (!this.validationErrors) {
          this.tab = 2
        }
      });
    },
    validateCustomerDetails() {
      this.$store.dispatch("projectData/validateCustomerDetails").then(() => {
        if (!this.validationErrors) {
          this.tab = 3
        }
      });
    },
    finishProject() {
      this.$store.dispatch("projectData/validateAndSubmitProject").then(() => {
        if (!this.validationErrors) {
          this.tab = 4
          router.push({ name: 'thank-you' });
        }
      });
    }
  },

  computed: {
    validatingProjectDataForm: {
      get () {
        return this.$store.getters['projectData/getValidatingProjectDataForm']
      },
      set (value) {
        this.$store.commit('projectData/setValidatingProjectDataForm', value)
      }
    },
    validationErrors: {
      get () {
        return this.$store.getters['projectData/getValidationErrors']
      },
      set (value) {
        this.$store.commit('projectData/setValidationErrors', value)
      }
    }
  }
}
</script>
