<template>
  <div class="customer-details-form-container">
    <v-container fluid v-if="validatingCustomerDataForm">
      <v-row>
          <v-col class="spinner-col">
            <v-progress-circular
              class="validating-customer-data-form-spinner"
              indeterminate
              color="primary"
              v-if="validatingCustomerDataForm"
            ></v-progress-circular>
          </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!validatingCustomerDataForm">
      <v-row v-if="validationErrors">
        <v-col>
          <v-alert
            dense
            outlined
            type="error"
          >
            {{ $t(`validation.generic`) }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
            <v-radio-group
              :label="$t(`customerDetails.customerType.label`)"
              v-model="customerType"
              row
              :error="$store.getters['projectData/hasErrors']('customer.type')"
              @change="removeErrorKey('customer.type')"
              :rules="[rules.required]"
            >
              <v-radio
                v-for="customerType in this.$store.getters['api/getCustomerTypes']"
                :key="'customerType' + customerType"
                name="customerType"
                :label="$t(`customerDetails.customerType.values.${customerType}`)"
                :value="customerType"
              ></v-radio>
            </v-radio-group>
          </v-col>
      </v-row>
      <private-customer-form 
        v-if="customerType == 'private'"
      >
      </private-customer-form>
      <commercial-customer-form 
        v-if="customerType == 'commercial'"
      >
      </commercial-customer-form>
    </v-container>
    </div>
</template>

<script>
import PrivateCustomerForm from './PrivateCustomerForm.vue'
import CommercialCustomerForm from './CommercialCustomerForm.vue'

export default {
  name: 'CustomerDetailsForm',

  components: {
    PrivateCustomerForm,
    CommercialCustomerForm,
  },

  data () {
    return {
      rules: {
        required: value => !!value || this.$t(`validation.required`),
      }
    }
  },

  computed: {
    customerType: {
      get () {
        return this.$store.getters['projectData/getCustomerType']
      },
      set (value) {
        this.$store.commit('projectData/setCustomerType', value)
      }
    },
    validatingCustomerDataForm: {
      get () {
        return this.$store.getters['projectData/getValidatingCustomerDataForm']
      },
      set (value) {
        this.$store.commit('projectData/setValidatingCustomerDataForm', value)
      }
    },
    validationErrors: {
      get () {
        return this.$store.getters['projectData/getValidationErrors']
      },
      set (value) {
        this.$store.commit('projectData/setValidationErrors', value)
      }
    },
  },

  methods: {
    removeErrorKey(key) {
      this.$store.dispatch('projectData/removeErrorKey', key)
    }
  },
}
</script>
